import { useEffect, useState, useMemo } from "react";
import { ClipLoader } from "react-spinners";
import CategoryCard from "./CategoryCard";
import axios from "axios";

function HomePage() {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const getData = () => {
    axios
      .get("/api/tools/getCategories")
      .then((res) => {
        setCategories(res.data);
        setLoading(false);
        const expiryTime = Date.now() + 3600 * 1000; // 1 hour from now
        localStorage.setItem(
          "categories",
          JSON.stringify({ data: res.data, expiryTime })
        );
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const cachedCategories = localStorage.getItem("categories");
    if (cachedCategories) {
      const { data, expiryTime } = JSON.parse(cachedCategories);
      if (Date.now() < expiryTime) {
        setCategories(data);
        setLoading(false);
      } else {
        // Cache expired, remove it
        localStorage.removeItem("categories");
        getData();
      }
    } else {
      getData();
    }
  }, []);


  if (loading) {
    return (
      <div className="flex h-[80vh] justify-center">
        <ClipLoader className="self-center" color="#ec5327" size={50} />
      </div>
    );
  }

  return (
    <>
    <div className="h-[108vh] bg-[#F1F1F2] bg-cover bg-center bg-fixed">
      <div className="h-full w-full backdrop-blur-md">
      <div className="grid grid-cols-3 gap-3 place-items-center">
        {categories?.map((category, index) => (
          <CategoryCard
            key={index}
            index={index}
            toolname={category.toolname}
            toolid={category.toolid}
            tooldesc={category.description}
            toolicon = {category.iconpath}
          />
        ))}
      </div>
      </div>
      </div>
    </>
  );
}

export default HomePage;
