import React, { useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import useUser from "Components/hooks/useUser";

const validationSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category name is required"),
  icon: Yup.mixed().required("Icon is required"),
});

const initialValues = {
  categoryName: "",
  icon: null,
  description: "",
};

const headerConfig = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const AddCategory = () => {
  const fileInputRef = useRef(null);
  const user = useUser();

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm },
    fileInputRef
  ) => {
    axios
      .post(
        "/api/tools/addCategory",
        {
          ...values,
          user: user.username,
        },
        headerConfig
      )
      .then((resp) => toast.success(resp.data))
      .catch(function (error) {
        if (error.response) {
          toast.error(error.response.data);
        }
      });
    resetForm();
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Clear file input visually after resetting the form
    }
    setSubmitting(false);
  };

  const handleFileInputChange = (event, form) => {
    form.setFieldValue("icon", event.currentTarget.files[0]);
    form.setFieldError("icon", "");
  };

  return (
    <>
      <center className="text-3xl font-semibold">Add Category</center>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) =>
          handleSubmit(values, actions, fileInputRef)
        }
      >
        {({ isSubmitting, resetForm, setFieldValue, values }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                htmlFor="categoryName"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Category Name
              </label>
              <Field
                type="text"
                id="categoryName"
                name="categoryName"
                className="block w-full mt-1 input-class border-gray-400"
              />
              <ErrorMessage
                name="categoryName"
                component="p"
                className="text-red-500 text-xs mt-1"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="icon"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Icon
              </label>
              <Field name="icon">
                {({ form }) => (
                  <>
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="icon"
                      name="icon"
                      accept="image/*"
                      className="block w-full mt-1 input-class border-gray-400"
                      onChange={(event) => handleFileInputChange(event, form)}
                    />
                    {values.icon && values.icon.name && (
                      <p className="text-sm text-gray-500 mt-1">
                        Selected file: {values.icon.name}
                      </p>
                    )}
                  </>
                )}
              </Field>
              <ErrorMessage
                name="icon"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Description
              </label>
              <Field
                as="textarea"
                id="description"
                name="description"
                className="block w-full mt-1 input-class border-gray-400"
              />
              <ErrorMessage
                name="description"
                component="p"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-primary disabled:bg-red-300"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => {
                  resetForm();
                  if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                  }
                }}
                className="btn-primary"
                disabled={isSubmitting}
              >
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddCategory;
