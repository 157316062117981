import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";

const FileUpload = ({ setFieldValue, files, field, form, error }) => {
  const removeFile = (indexToRemove) => {
    const newFiles = files.filter((_, index) => index !== indexToRemove);
    setFieldValue("files", newFiles);
  };

  const resetFiles = () => {
    setFieldValue("files", []);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFieldValue("files", [...files, ...acceptedFiles]);
    },
  });

 

  const fileItems =
    files && Array.isArray(files)
      ? files.map((file, index) => (
          <div
            key={index}
            className="flex items-center border border-gray-200 rounded p-2 mb-2"
          >
            <div className="w-10 h-10 flex-shrink-0 bg-gray-200 rounded-full flex items-center justify-center mr-3">
              <span className="text-gray-700">{index + 1}</span>
            </div>
            <div className="flex-grow truncate">{file.name}</div>
            <div className="flex space-x-2">
              <MdDelete
                className="text-red-500 hover:text-red-700 ml-2 cursor-pointer"
                onClick={() => removeFile(index)}
                size={23}
              />
            </div>
          </div>
        ))
      : null;

  const noFilesMessage = (
    <p className="text-center text-gray-500">No files uploaded yet.</p>
  );

  return (
    <div>
      <div
        {...getRootProps()}
        className="mt-2 border-2 border-gray-400 mx-auto bg-gray-200 border-dotted p-8 rounded-lg text-center text-slate-600 cursor-pointer"
      >
        <input {...getInputProps()} id="files"/>
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      {form.touched['files'] && error && <div className="text-red-500 text-xs mt-1">{error}</div>}
      {fileItems && fileItems.length > 0 ? (
        <div className="mt-8 grid grid-cols-3 gap-x-4 gap-y-2">{fileItems}</div>
      ) : (
        noFilesMessage
      )}
      {files && files.length > 0 && (
        <div className="w-full flex justify-center ">
          <button className="btn-primary" onClick={resetFiles}>
            Reset Files
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
