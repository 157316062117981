import axios from "axios";
import Toast from "features/Toast";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import UserModal from "./UserModal";
import useUser from "Components/hooks/useUser";
import TableForSupportTools from "../Tools/utils/TableForSupportTools";

const AllUsersUnderManager = () => {
  const user = useUser();
  const [users, setUsers] = useState(null);
  const [showmodal,setShowmodal] = useState(false);
  const [selecteduser, setSelecteduser] = useState(null);


  const getUsers = async () => {
    try {
      const response = await axios.get(
        `/api/users/getAllUsersUnderManager/${user.id}`
      );
      setUsers(response.data);
    } catch (error) {
      if (error.message) {
        Toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleclick = (curuser) => {
    setSelecteduser(curuser);
  }

  useEffect(()=>{

    if(selecteduser!=null)
      setShowmodal(true);

  },[selecteduser])

  if (!users) {
    return (
      <div className="flex h-[80vh] justify-center">
        <ClipLoader className="self-center" color="#ec5327" size={50} />
      </div>
    );
  }
  const tableHeaders = [
    "User Name",
    "Is Deleted",
    "Is Verified",
    "Is Disabled",
    "Last Login",
    "Actions"
  ];

  const tableData = users.map((curuser) => {
    return {
      "User Name": curuser.username,
      "Is Deleted": curuser.isdeleted ? "Yes" : "No",
      "Is Verified": curuser.isverified ? "Yes" : "No",
      "Is Disabled": curuser.isdisabled ? "Yes" : "No",
      "Last Login":  curuser.lastlogin ? new Date(curuser.lastlogin).toLocaleString() : "Not Logged in yet",
      "Actions": <button className={`${curuser.isdeleted===1?'bg-gray-600 text-white cursor-not-allowed h-[30px] w-[80px] rounded-md':'bg-[#ec5327]  hover:bg-[#BD4220] text-white h-[30px] w-[80px] rounded-md'}`} disabled={curuser.isdeleted===1} onClick={()=>handleclick(curuser)}>Edit</button>,
    };
  });

  return (
      <div>
        <TableForSupportTools tableHeaders={tableHeaders} data={tableData} />
        {showmodal && 
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
          <UserModal 
            className="flex justify-center items-center"
            curuser={selecteduser}
            setShowmodal={setShowmodal}
            setSelecteduser={setSelecteduser}
          />
          </div>
        }
      </div>
  );
};

export default AllUsersUnderManager;