import { useSelector } from 'react-redux';

function useUser() {
    const user = useSelector( (state) => state.user.user);
    if (!user){
        const rv = JSON.parse(sessionStorage.getItem('user'))
        return rv;
    }
    return user;
}

export default useUser