import { useEffect, useState } from "react";
import { toast } from "react-toastify";


const ApproveModal = ({ user, setShowmodal, userdata, setUserdata, onClose }) => {
    const [checkboxItems, setCheckboxItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handlecheckboxchange = (event, itemID) => {
      const isChecked = event.target.checked;
      setSelectedItems((prevSelectedItems) =>
        isChecked
          ? [...prevSelectedItems, itemID]
          : prevSelectedItems.filter((id) => id !== itemID)
      );
    }

    const handleSubmit = async () => {
      try {
          const response = await fetch(`/api/auth/approve-request`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ selectedItems, user })
          });
  
          const data = await response.json();
          toast.success("User created successfully");
          setShowmodal(false);
          setUserdata(userdata.filter((cur) => cur.id !== user.id))
          
      } catch (error) {
          toast.error("Internal server error!");
      }
      
  };

    useEffect(() => {
      const fetchData = async () => {
            const response = await fetch(`/api/auth/gettools`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        const data = await response.json();
        setCheckboxItems(data);
      };
  
      fetchData();
    }, [user]);
  
    return (
        <dialog open className="p-6 rounded-lg bg-white w-full max-w-md mx-auto">
          <h2 className="text-xl font-semibold mb-2">Approve {user.username}</h2>
          <p className="text-xl  mb-5">Select the permissions to assign:</p>
          <div className="space-y-2">
            {checkboxItems.map((item) => (
              <label key={item.toolid} className="flex items-center">
                <input 
                  type="checkbox" 
                  className="mr-2"
                  checked={selectedItems.includes(item.toolid)}
                  onChange={(event) => handlecheckboxchange(event, item.toolid)} />
                <span>{item.toolname}</span>
              </label>
            ))}
          </div>
          <div className="flex justify-end mt-4">
            <button
                onClick={onClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
                Close
            </button>
            <button
                onClick={handleSubmit}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                Confirm
            </button>
            </div>
        </dialog>
      );
  };

export default ApproveModal;