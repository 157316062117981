import { useEffect, useState } from "react";
import { FaClipboard } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const UserModal = ({ curuser, setShowmodal, setSelecteduser}) => {

    const [clickedonce, setClickedonce] = useState(false);
    const [deleteUserpopup, setDeleteUserpopup] = useState(false);
    const [newpassword, setnNewpassword] = useState('old');
    const [isloading, setIsloading] = useState(false);
    const [resetPasspopup, setResetPasspopup] = useState(false);
    const [permissionspopup, setPermissionspopup] = useState(false);
    const [checkboxItems, setCheckboxItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allItemIds = checkboxItems.map(item => item.toolid);
            setSelectedItems(allItemIds);
        } else {
            setSelectedItems([]);
        }
        setSelectAllChecked(isChecked);
    };

    useEffect(()=>{
        if(selectedItems.length === checkboxItems.length)
            setSelectAllChecked(true);
        else
            setSelectAllChecked(false);
    },[selectedItems])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(newpassword);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
    };

    const handlecheckboxchange = (event, itemID) => {
        const isChecked = event.target.checked;

        setSelectedItems(prevSelectedItems => {
            let updatedSelectedItems;
    
            if (isChecked) {
                if (!prevSelectedItems.includes(itemID)) {
                    updatedSelectedItems = [...prevSelectedItems, itemID];
                } else {
                    updatedSelectedItems = [...prevSelectedItems];
                }
            } else {
                updatedSelectedItems = prevSelectedItems.filter(id => id !== itemID);
            }
    
            return updatedSelectedItems;
        });
    }

    const handleDisableUser = async ()=>{

        try {
            const response = await fetch(`/api/users/toggleDisableuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ curuser } )
            });
    
            const data = await response.json();
            curuser.isdisabled = (curuser.isdisabled === 1 ? 0 : 1);
            toast.success(data.message);
    
        } catch (error) {
            toast.error("Internal server error!");
        }
    }

    const handleDeleteUser = ()=>{
        setDeleteUserpopup(true);
    }

    const handleDeletepopup = async () => {
        try {
            const response = await fetch(`/api/users/deleteUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ curuser } )
            });
    
            const data = await response.json();
            curuser.isdeleted = 1;
            toast.success(data.message);
            
        } catch (error) {
            toast.error("Internal server error!");
        }
        setDeleteUserpopup(false);
    }

    const generatePassword = () => {
        const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const numberChars = '0123456789';
        const symbolChars = '!@#$%^&*()-_+=<>?';
      
        const getRandomChar = (charSet) => {
          return charSet[Math.floor(Math.random() * charSet.length)];
        };
      
        let password = '';
        password += getRandomChar(uppercaseChars);
        password += getRandomChar(lowercaseChars);
        password += getRandomChar(numberChars);   
        password += getRandomChar(symbolChars);   
      
        const remainingLength = 12 - password.length;
        for (let i = 0; i < remainingLength; i++) {
          const charSet = uppercaseChars + lowercaseChars + numberChars + symbolChars;
          password += getRandomChar(charSet);
        }
      
        password = password.split('').sort(() => Math.random() - 0.5).join('');
      
        return password;
      };

    const handleResetPassword = ()=>{
        setIsloading(true);

        setResetPasspopup(true);
        setnNewpassword(generatePassword());

        setIsloading(false);
    }

    const handlePasswordpopup = async ()=> {
        try{
            const response = await fetch('/api/users/resetPassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ curuser, newpassword } )
            });

            const data = await response.json();
            toast.success(data.message);
        }
        catch(error){
            toast.error("Internal server error!");
        }

        setResetPasspopup(false);
    }

    const handleChangePermissions = ()=>{
        setPermissionspopup(true);
    }

    const savePermissions = async ()=>{

        try {
            const response = await fetch(`/api/users/modifyPermissions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ curuser, selectedItems } )
            });
            
            const data = await response.json();
            toast.success(data.message);
            
        } catch (error) {
            toast.error("Internal server error!");
        }
        
        setPermissionspopup(false);
    }

    useEffect(() => {
        setIsloading(true);

        const fetchData = async () => {
              const response = await fetch(`/api/auth/gettools`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({})
          });
          const data = await response.json();
          setCheckboxItems(data);
        };
    
        fetchData();

        setIsloading(false);

      }, []);

    useEffect(()=>{
        setIsloading(true);

        const fetchData = async () => {
            const response = await fetch(`/api/users/getPermissionsforuser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ curuser })
            });
            const data = await response.json();
    
            const uniqueToolIds = new Set();
            Object.values(data).forEach(entry => {
                uniqueToolIds.add(entry.toolid);
            });
    
            setSelectedItems(Array.from(uniqueToolIds));
        };
    
        fetchData();

        setIsloading(false);
    },[])

    if (isloading) {
    return (
        <div className="flex h-[80vh] justify-center">
        <ClipLoader className="self-center" color="#ec5327" size={50} />
        </div>
    );
    }


    return(
        <dialog open className="usermodal justify-center text-center mb-5 p-6 rounded-lg text-white bg-slate-900 w-auto max-w-md mx-auto">
            <h2 className="text-xl font-semibold mb-8">Actions for {curuser.username}</h2>
    
            {/* Disable/Enable User */}
            {curuser.isdisabled===0 && <div className="flex items-center justify-between mb-4">
                <span>Disable User account temporarily</span>
                <button className={`${clickedonce?'bg-slate-900 text-white cursor-not-allowed h-[35px] w-[110px] rounded-md':'bg-red-500 hover:bg-red-700 text-white h-[35px] w-[110px] rounded-md'}`} disabled={clickedonce} onClick={() => {handleDisableUser(); setClickedonce(true);}}>Disable</button>
            </div>
            }

            {curuser.isdisabled===1 && <div className="flex items-center justify-between mb-4">
                <span>Enable User account</span>
                <button className={`${clickedonce?'bg-slate-900 text-white cursor-not-allowed h-[35px] w-[110px] rounded-md':'bg-green-500 hover:bg-green-700 text-white h-[35px] w-[110px] rounded-md'}`} disabled={clickedonce} onClick={() => {handleDisableUser(); setClickedonce(true);}}>Enable</button>
            </div>
            }

            {/* Delete Permanently */}
            <div className="flex items-center justify-between mb-4">
                <span>Delete permanently</span>
                <button className={`${curuser.isdeleted===0?'bg-red-500 hover:bg-red-700 text-white h-[35px] w-[110px] rounded-md': 'bg-slate-900 text-white cursor-not-allowed h-[35px] w-[110px] rounded-md'}`} disabled={curuser.isdeleted} onClick={() => handleDeleteUser()}>Delete</button>
            </div>

            {deleteUserpopup && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-lg bg-black bg-opacity-70 z-50">
                <div className="popup-inner ">
                    <p className="font-bold text-3xl mb-5">Are you sure you want to delete {curuser.username}?</p>
                    <p className="text-2xl mb-10 font-bold text-red-600">Note that you CANNOT undo this action.</p>
                    <div className="popup-buttons flex justify-between">
                    <button className="bg-green-500 hover:bg-green-700 text-white h-[45px] w-[180px] rounded-md" onClick={()=> handleDeletepopup()}>Confirm delete</button>
                    <button className="bg-red-500 hover:bg-red-700 text-white h-[45px] w-[130px] rounded-md" onClick={() => setDeleteUserpopup(false)}>Cancel</button>
                    </div>
                </div>
                </div>
            )}
    
            {/* Reset Password */}
            <div className="flex items-center justify-between mb-4">
                <span>Reset password</span>
                <button className="bg-yellow-500 hover:bg-yellow-700 text-white h-[35px] w-[110px] rounded-md" onClick={() => handleResetPassword()}>Reset</button>
            </div>

            {resetPasspopup && isloading===false && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-lg bg-black bg-opacity-70 z-50">
                <div className="popup-inner">
                    <p className="font-bold text-3xl mb-5">Are you sure you want to update the password for {curuser.username} to the following?</p>
                    <div className="flex justify-center items-center mb-5">
                    <p className="text-xl mr-2">{newpassword}</p>
                    <button onClick={copyToClipboard} className="text-gray-600 focus:outline-none">
                        <FaClipboard className="w-6 h-6 text-yellow-400" />
                    </button>
                </div>
                {isCopied && <p className="text-green-500 mb-2">Copied to clipboard!</p>}
                    <p className="text-2xl mb-10 font-bold text-red-600">Note that you CANNOT undo this action.</p>
                    <div className="popup-buttons flex justify-center space-x-60">
                    <button className="bg-green-500 hover:bg-green-700 text-white h-[45px] w-[180px] rounded-md" onClick={()=> handlePasswordpopup()}>Confirm change</button>
                    <button className="bg-red-500 hover:bg-red-700 text-white h-[45px] w-[130px] rounded-md" onClick={() => setResetPasspopup(false)}>Cancel</button>
                    </div>
                </div>
                </div>
            )}
    
            {/* Change Permissions */}
            <div className="flex items-center justify-between mb-4">
                <span>Change permissions</span>
                <button className="bg-blue-500 hover:bg-blue-700 text-white h-[35px] w-[110px] rounded-md" onClick={() => handleChangePermissions()}>Change</button>
            </div>

            {permissionspopup && 
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-lg bg-black bg-opacity-70 z-50">
                <dialog open className="p-6 rounded-lg bg-gray-300 w-full max-w-md mx-auto">
                <h2 className="text-xl font-semibold mb-2">Change permissions for {curuser.username}</h2>
                <p className="text-xl  mb-5">Select the permissions to assign:</p>
                <div className="space-y-2">
                <label className="flex justify-end items-center">
                    <input 
                        type="checkbox" 
                        className="mr-2"
                        checked={selectAllChecked}
                        onChange={handleSelectAllChange}
                    />
                    <span>Select All</span>
                </label>
                {checkboxItems.map((item) => (
                    <label key={item.toolid} className="flex items-center">
                        <input 
                            type="checkbox" 
                            className="mr-2"
                            checked={selectedItems.includes(item.toolid)}
                            onChange={(event) => handlecheckboxchange(event, item.toolid)} 
                        />
                        <span className={selectedItems.includes(item.toolid) ? "text-green-600" : ""}>{item.toolname}</span>
                    </label>
                ))}
            </div>
                <div className="flex justify-center mt-4">
                  <button
                      onClick={()=>{setPermissionspopup(false); savePermissions();}}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                  >
                      Save changes
                  </button>
                  </div>
               </dialog>
                </div>
            }
    
            <div className="flex justify-center mt-4">
                <button onClick={() => { setShowmodal(false); setSelecteduser(null); setClickedonce(false);}} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
                    Done
                </button>
            </div>
        </dialog>
    );
    
};

export default UserModal;