import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HTMLViewer from './HTMLViewer';
import { ClipLoader } from "react-spinners";


const RenderTools = () => {
  const { toolName } = useParams();

  const [htmlContent, setHtmlContent] = useState('');

  const [isloading, setIsLoading] = useState(false);


  useEffect(() => {
    const getToolInformation = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`/api/tools/${toolName}`);
        setHtmlContent(response.data);
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data);
        }
      }
      finally{
        setIsLoading(false);
      }
    };

    getToolInformation();
  }, []);

if (isloading){
  return (
    <div className="flex h-[80vh] justify-center">
      <ClipLoader className="self-center" color="#ec5327" size={50} />
    </div>
  );
}

  return (
    <HTMLViewer htmlContent={htmlContent} toolName = {toolName} setHtmlContent = {setHtmlContent}/>
  )
};

export default RenderTools;
