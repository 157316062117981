import useUser from "Components/hooks/useUser";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const AllToolsForCategory = () => {
  const user = useUser();

  const { toolID } = useParams();
  const [loading, setLoading] = useState(true);
  const [tools, setTools] = useState();
  
  const getAllToolsForCategory = () => {
    axios
      .get(`/api/tools/getAllToolsForCategory/${toolID}/${user.id}`)
      .then((res) => {
        setTools(res.data);
        setLoading(false);
        const expiryTime = Date.now() + 3600 * 1000; // 1 hour from now
        localStorage.setItem(
          `tools/${toolID}`,
          JSON.stringify({ data: res.data, expiryTime })
        );
      })
      .catch((err) => toast.error(err.message));
  };

  const fetchTools = async () => {
    const cachedTools = localStorage.getItem(`tools/${toolID}`);
    if (cachedTools) {
      const { data, expiryTime } = JSON.parse(cachedTools);
      if (Date.now() < expiryTime) {
        setTools(data);
        setLoading(false);
      } else {
        localStorage.removeItem(`tools/${toolID}`);
        await getAllToolsForCategory();
      }
    } else {
      await getAllToolsForCategory();
    }
  };

  useEffect(() => {
    fetchTools();
  }, [toolID]);
  

  if (loading) {
    return (
      <div className="flex h-[80vh] justify-center">
        <ClipLoader className="self-center" color="#ec5327" size={50} />
      </div>
    );
  }

  if (!tools || tools.length === 0) {
    return (
      <div className="flex place-items-center place-content-center text-center h-[70vh]">
        <div className="backdrop-blur-lg border border-gray-200 rounded-md shadow-md py-4 px-6 text-lg">
          Bad Luck!{"\n"}
          Either No Tools Added To This Category{"\n"}
          Or You Don't have Permission to{"\n"}
          View Tools in this Category!
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-3 gap-5 mx-6 p-2 place-content-center">
        {tools.map((tool, index) => {
          return (
            <Link
              key={index}
              to={`/tools/${tool.toolname}`}
              className="list-tools-card w-full"
            >
              <div className="px-6 py-4 text-center">
                <div className="text-xl mb-2">{tool.toolname}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export default AllToolsForCategory;
