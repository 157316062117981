import logo from "Assets/Images/logo.png";
import { useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../redux/userSlice";
import { toast } from "react-toastify";
import useUser from "Components/hooks/useUser";

function Navbar() {
  let navigate = useNavigate();
  // valuesForOptionsMenu = ['hidden', 'flex-col', 'block']

  const user = useUser();

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const dispatch = useDispatch();

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleLogOut() {
    dispatch(logout());
    setIsOptionsOpen(false);
    localStorage.clear();
    sessionStorage.clear();
    toast.success("You have successfully logged out!");
    navigate("/");
  }

  return (
    <nav className="flex relative items-center justify-between w-full text-lg font-small p-1 h-[56px] bg-gray-900 mb-4">
      <NavLink activeclassname="active" to="/">
        <img className="h-12 ml-2" src={`${logo}`} alt="logo" />
      </NavLink>

      <ul
        className="flex flex-row items-center px-3 my-1 last:pr-0 
        bg-transparent
        text-white
        top-0 right-0
        static
        h-fit w-fit
        "
      >
        <li className="navbar-li">
          <NavLink activeclassname="active" to="/home">
            Home
          </NavLink>
        </li>
        <li className={`${user?.isadmin === 1 ? "" : "hidden"} navbar-li`}>
          <NavLink activeclassname="active" to="/users">
            Users
          </NavLink>
        </li>
        <li className={`${user?.isadmin === 1 ? "" : "hidden"} navbar-li`}>
          <NavLink activeclassname="active" to="/all-tools">
            Tools
          </NavLink>
        </li>
        <li className={`${user?.isadmin === 1 ? "" : "hidden"} navbar-li`}>
          <NavLink activeclassname="active" to="/home/admincontrols">
            Admin controls
          </NavLink>
        </li>

        <li
          onClick={() => setIsOptionsOpen((prev) => !prev)}
          className="w-10 h-10 cursor-pointer"
        >
          <FaUserCircle size={40} />

          {isOptionsOpen && (
            <div
              ref={dropdownRef}
              className="absolute bg-white bg-opacity-70 backdrop-blur-lg border border-gray-200 rounded-md shadow-md py-2 px-4 right-0 mt-1 mr-2 z-10"
            >
              <p className="font-semibold text-gray-800 text-right mb-2">
                Hello, {user?.username}
              </p>
              <button
                className="text-right text-gray-700 cursor-pointer hover:text-red-600"
                onClick={() => handleLogOut()}
              >
                Logout
              </button>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
