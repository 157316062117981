import { useState } from "react";
import { IoIosPeople } from "react-icons/io";
import { MdOutlinePassword, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "../../../redux/userSlice";
import { FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import login_image from "../../../Assets/Images/login_image.jpg"

export default function Landingpage() {
  const [useremail, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(0);
  const [sending, setSending] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = async () => {
    setSending(true);
    try {
        const response = await fetch(`/api/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ useremail, password })
        });

        const data = await response.json();
        const {user,token} = data;

        if(response.status===200){
          const newUser = {
            ...user,
            password : null
          }
          sessionStorage.setItem('user', JSON.stringify(newUser))
          dispatch(setUser(user));
          toast.success(`Welcome back ${user.username}!`);
          navigate('/home');
        }
        else{
          toast.error(data.message);
        }
    } catch (error) {
        toast.error("Internal server error!");
    }
    finally{
      setSending(false);
      setPassword("");
      setUseremail("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="flex justify-center items-center h-[108vh] -mt-16 bg-gray-200">
    <div className="flex justify-center items-center h-[40vh] w-[100vw]">
      <div className="flex px-8 py-12 justify-center items-center rounded-3xl text-center w-[85vw] h-[80vh] border border-gray-100 shadow-md bg-white bg-opacity-60 backdrop-blur">
        <div className="w-1/2">
        <img src={login_image} alt="login_image" className="w-[35vw] h-[70vh] object-cover rounded-3xl" />
        </div>
        <div className="w-1/2 ml-7">
          <div>
            <h2 className="flex justify-center text-3xl font-bold tracking-tight text-center mb-12">
              <IoIosPeople className="icon" /> Welcome to WinDash!
            </h2>
          </div>

          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your email"
                name="useremail"
                value={useremail}
                onChange={(e) => setUseremail(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <div>
            <label className="flex items-center justify-between ml-5">
              <div>
                <MdOutlinePassword className="icon text-xl mr-4"></MdOutlinePassword>
              </div>
              <div className="flex items-center mr-auto">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black pr-10" // Added padding right
                  placeholder="Enter Password"
                  name="password"
                  value={password}
                  onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="text-gray-600 px-1 py-4 mb-4" 
                >
                  {showPassword ? <MdVisibilityOff className="text-xl" /> : <MdVisibility className="text-xl" />}
                </button>
              </div>
            </label>
          </div>
          <button
            onClick={submitHandler}
            disabled={sending}
            className="btn-primary px-6 mt-8 mb-4 w-[30vw] h-[6vh] rounded-xl mr-5"
          >
            LOGIN
          </button>
          <div>
            New user? <Link to="/register" className="text-[#ec5327] border-b border-[#ec5327]">Register here</Link>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
