import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

const HTMLViewer = ({ htmlContent, setHtmlContent, toolName }) => {
  const handleLinkClick = async (e) => {
    e.preventDefault(); // Prevent default behavior (page refresh)
    const href = e.target.getAttribute("href");
    try {
      const response = await axios.get(`/api/${toolName}/file/${href}`);
      setHtmlContent(response.data);
    } catch (error) {
      toast.error("Error fetching content");
    }
  };

  return (
    <div
      className="text-base"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      onClick={(e) => {
        if (e.target.tagName === "A") {
          handleLinkClick(e);
        }
      }}
    />
  );
};

export default HTMLViewer;
