import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import TableForSupportTools from "./utils/TableForSupportTools";
import { MdDeleteForever } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import CustomModal from "Components/Pages/Tools/CustomModal";
import { toast } from "react-toastify";
import useUser from "Components/hooks/useUser";

export const ListAllTools = () => {
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [tools, setTools] = useState([]);

  const getAllTools = async () => {
    try {
      const response = await axios.get("/api/tools/all/getAllTools");
      const data = response.data;
      setTools(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTools();
  }, []);

  if (loading) {
    return (
      <div className="flex h-[80vh] justify-center">
        <ClipLoader className="self-center" color="#ec5327" size={50} />
      </div>
    );
  }

  const deleteTool = async (toolid) => {
    try {
      const resp = await axios.delete(`/api/tools/deleteTool/${toolid}`);
      await getAllTools();
      toast.success(resp.data);
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      }
    }
  };
  const handleDeleteClick = (toolid) => {
    setModalData({
      modalHeading: "Delete",
      modalContent: ["Are you sure?"],
      toolid: toolid,
      apiFunction: deleteTool,
    });
    setModalIsOpen(true);
  };

  const getPermittedUsersForTool = async (toolid) => {
    try {
      const resp = await axios.get(
        `/api/tools/permittedUsersForTool/${user?.id}/${toolid}`
      );
      return resp.data;
    } catch (err) {
      if (err.message) {
        toast.error(err.message);
      }
    }
  };

  const editToolUsers = async (toolid) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let userIDs = []
    checkboxes.forEach((checkbox, index) => {
      if (checkbox.checked) {
        const userID = checkbox.value;
        userIDs.push(Number(userID));
      }
    });
    try{
      const resp = await axios.put('/api/tools/setPermittedUsersForTool', {
        managerID : user.id,
        userIDs,
        toolid
      })
      toast.success(resp.data)
    } 
    catch (error){
      if (error.message)
        toast.error(error.message)
    }
  };
  
  
  const handleEditClick = async (toolid) => {
    const users = await getPermittedUsersForTool(toolid);
    const checkboxes = users.hasPermission.map((user, index) => {
      return (
        <div key={index}>
          <input
            type="checkbox"
            className="mr-2"
            id={`user-${index}`}
            value={user.id}
            defaultChecked={true} // Default checked for users with permission
          />
          <label htmlFor={`user-${index}`}>{user.username}</label>
        </div>
      );
    });
  
    users.doesNotHavePermission.forEach((user, index) => {
      checkboxes.push(
        <div key={index + users.hasPermission.length}>
          <input
            type="checkbox"
            className="mr-2"
            id={`user-${index + users.hasPermission.length}`}
            value={user.id}
          />
          <label htmlFor={`user-${index + users.hasPermission.length}`}>
            {user.username}
          </label>
        </div>
      );
    });
  
    setModalData({
      modalHeading: "Edit users for Tool",
      modalContent: checkboxes,
      toolid: toolid,
      apiFunction: editToolUsers,
    });
    setModalIsOpen(true);
  };
  

  const actions = (toolID) => (
    <div className="flex justify-between text-xl gap-5">
      <MdDeleteForever
        className="inline cursor-pointer"
        data-tooltip-id="tooltip"
        data-tooltip-content="Delete this Tool"
        data-tooltip-place="top"
        onClick={() => handleDeleteClick(toolID)}
      />
      <FaUserEdit
        className="inline cursor-pointer"
        data-tooltip-id="tooltip"
        data-tooltip-content="Edit permitted users list"
        data-tooltip-place="top"
        onClick={() => handleEditClick(toolID)}
      />
    </div>
  );

  const tableHeaders = ["Tool Name", "Created By", "Created Date", "Actions"];
  const tableData = tools.map((tool) => {
    return {
      "Tool Name": tool.toolname,
      "Created By": tool.createdby,
      "Created Date": new Date(tool.createddate).toLocaleString(),
      Actions: actions(tool.toolid),
    };
  });

  return (
    <>
      <div className="float-right flex gap-4 mr-2">
        <Link to={"/tools/add-tool"}>
          <button className="btn-primary">Add Tool</button>
        </Link>
        <Link to={"/tools/add-category"}>
          <button className="btn-primary">Add Category</button>
        </Link>
      </div>
      <TableForSupportTools tableHeaders={tableHeaders} data={tableData} />
      <CustomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        modalData={modalData}
      />

      <Tooltip id="tooltip" className="text-xs p-1" />
    </>
  );
};
