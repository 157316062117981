import React from 'react';

const UserCard = ({ user, handleApprove, handleReject }) => {
  return (
    <div className="bg-white shadow-md p-6 rounded-lg w-full max-w-sm mx-auto my-5">
      <p className="text-xl font-semibold mb-4">Username: {user.username}</p>
      <div className="flex justify-between">
        <button
          onClick={() => handleApprove(user)}
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
        >
          Approve
        </button>
        <button
          onClick={() => handleReject(user.id, user.username)}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
        >
          Reject
        </button>
      </div>
    </div>
  );
};

export default UserCard;
