import AddTool from "Components/Pages/AddTools/AddTool";
import { ListAllTools } from "Components/Pages/Tools/ListAllTools";
import AllToolsForCategory from "Components/Pages/Tools/AllToolsForCategory";
import RenderTools from "Components/Pages/Tools/RenderTools";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import HomePage from "./Components/Pages/HomePage/HomePage";
import Landingpage from "./Components/Pages/Auth/Landingpage";
import Toast from "./features/Toast";
import AddCategory from "Components/Pages/AddTools/AddCategory";
import RegistarPage from "Components/Pages/Auth/RegisterPage";
import AdminControls from "Components/Pages/Admincontrols/Admincontrols";
import AllUsersUnderManager from "Components/Pages/Users/AllUsersUnderManager";
import { ProtectedRoutes } from "Components/ProtectedRoutes";

function App() {
  const location = useLocation();
  //TODO : remove proxy from package.json in prod
  const hideOnRoutes = ['/', '/register', '*'];

  return (
    <>
      {!hideOnRoutes.includes(location.pathname) && <Navbar />}
      <Routes location={location} key={location.pathname}>
        
        <Route path="/" element={<Landingpage />} />
        <Route path="/register" element={<RegistarPage />} />

        <Route element={<ProtectedRoutes />}>
          <Route path="/home" element={<HomePage />} />
          <Route
            path="/all/:toolName/:toolID"
            element={<AllToolsForCategory />}
          />
          <Route path="/tools/add-tool" element={<AddTool />} />
          <Route path="/tools/add-category" element={<AddCategory />} />
          <Route path="/tools/:toolName" element={<RenderTools />} />
          <Route path="/users" element={<AllUsersUnderManager />} />
          <Route path="/logs" element={<HomePage />} />
          <Route path="/all-tools" element={<ListAllTools />} />
          <Route path="/home/admincontrols" element={<AdminControls />} />
        </Route>

        <Route path="*" element={<h1> Not Found</h1>} />

      </Routes>
      <Toast />
    </>
  );
}

export default App;
