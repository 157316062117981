import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FileUpload from "./FileUpload";
import axios from "axios";
import { toast } from "react-toastify";
import useUser from "Components/hooks/useUser";

const AddTool = () => {
  const [categories, setCategories] = useState([]);

  const user = useUser();


  useEffect(() => {
    const getCategories = async () => {
      try {
        const resp = await axios.get("/api/tools/getCategories");
        setCategories(resp.data);
      } catch (error) {
        throw error;
      }
    };

    getCategories();

    return () => {
      setCategories([]);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    toolcategory: Yup.string().required("Tool category is required"),
    toolname: Yup.string().required("Tool name is required"),
    tooldescription: Yup.string().required("Tool description is required"),
    files: Yup.array()
    .required("Files are required")
    .test("file-upload", "At least one file must be uploaded and named 'index.py'", (value) => {
      if (!value || value.length === 0) {
        return false;
      }
      const fileNames = value.map(file => file.name);
      return fileNames.includes("index.py");
    }),
  });

  const initialValues = {
    toolcategory: "",
    toolname: "",
    tooldescription: "",
    files: [],
  };

  const headerConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post("/api/tools/addTool", 
      {
        ...values,
        user : user.username
      }
      , headerConfig)
      .then((resp) => {
        toast.success(resp.data);
        resetForm();
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data);
        }
      });
    setSubmitting(false);
  };

  return (
    <>
      <center className="text-3xl font-semibold">Add Tool</center>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4">
              <label
                htmlFor="toolcategory"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Tool Category
              </label>
              <Field
                as="select"
                name="toolcategory"
                id="toolcategory"
                className="block w-full mt-1 input-class border-gray-400"
              >
                <option value="">Select Tool Category</option>
                {categories.map((category, index) => {
                  return (
                    <option key={index} value={category.toolname}>
                      {category.toolname}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                name="toolcategory"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="toolname"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Tool Name
              </label>
              <Field
                type="text"
                name="toolname"
                id="toolname"
                className="block w-full mt-1 input-class border-gray-400"
              />
              <ErrorMessage
                name="toolname"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="tooldescription"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Tool Description
              </label>
              <Field
                as="textarea"
                name="tooldescription"
                id="tooldescription"
                className="block w-full mt-1 input-class border-gray-400"
              />
              <ErrorMessage
                name="tooldescription"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="files" className="block text-gray-700 text-sm font-bold mb-2">
                Upload Files
              </label>
              <Field
                name="files"
                component={FileUpload}
                setFieldValue={setFieldValue}
                files={values.files}
                error={errors.files} 
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn-primary disabled:bg-red-300"
              >
                Submit
              </button>
              <button
                type="reset"
                className="btn-primary"
                disabled={isSubmitting}
              >
                Reset
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTool;
