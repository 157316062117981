import React, { useEffect, useState } from "react";

const TableForSupportTools = ({ tableHeaders, data }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const queryLower = query.toLowerCase();
    const matchedResults = data.filter((object) =>
      Object.values(object).some(
        (value) => String(value).toLowerCase().includes(queryLower)
      )
    );

    setFilteredData(matchedResults);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <label htmlFor="search">Search: </label>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          id="search"
          className="input-class border-gray-400"
        />
      </div>
      <table className="table-auto border-collapse border border-gray-400">
        <thead>
          <tr className="bg-gray-200">
            {tableHeaders.map((tableHeader, index) => (
              <th key={index} className="px-4 py-2 border border-gray-400">
                {tableHeader}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.slice(startIndex, endIndex).map((item, rowIndex) => (
            <tr key={rowIndex}>
              {tableHeaders.map((header, colIndex) => (
                <td
                  key={colIndex}
                  className="border border-gray-400 px-4 py-2"
                >
                  {item[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex gap-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="btn-primary disabled:opacity-50"
        >
          Previous
        </button>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="btn-primary disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableForSupportTools;
