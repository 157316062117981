import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import UserCard from "./usercard";
import ApproveModal from "./approvemodal";
import useUser from "Components/hooks/useUser";

const Admincontrols = () => {
  // const mgremail = useSelector((state) => state.user.user.useremail);
  const user = useUser();
  const mgremail = user.useremail;

  const [userdata, setUserdata] = useState(null);
  const [isloading, setisLoading] = useState(true);
  const [showmodal, setShowmodal] = useState(false);
  const [selecteduser, setSelecteduser] = useState(null);

  const refreshHandler = async () => {
    setisLoading(true);
    try {
      const response = await fetch(`/api/auth/account-requests`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mgremail }),
      });

      const data = await response.json();
      setUserdata(data);
    } catch (error) {
      toast.error("Internal server error!");
    }

    setisLoading(false);
  };

  useEffect(() => {
    refreshHandler();
  }, []);

  const handleApprove = (user) => {
    setShowmodal(true);
    setSelecteduser(user);
  };

  const handleReject = async (id, username) => {
    try {
      const response = await fetch(`/api/auth/deleteuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      });

      const data = await response.json();
      setUserdata(userdata.filter((user) => user.id !== id));
    } catch (error) {
      toast.error("Internal server error!");
    }
  };

  return (
    <div>
      <h1>Account Requests</h1>
      <button
        onClick={refreshHandler}
        className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded"
      >
        Refresh
      </button>
      <div>
        {isloading ? (
          <p>Click refresh button to see the requests if any...</p>
        ) : (
          userdata.map((user) => (
            <UserCard
              key={user.id}
              user={user}
              handleApprove={handleApprove}
              handleReject={handleReject}
            />
          ))
        )}
      </div>
      {(!userdata || userdata.length === 0) && (
        <p className="text-center text-gray-500 mt-8">
          No requests as of now...
        </p>
      )}

      <div>
        {showmodal && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            <div className="p-6 rounded-lg bg-white w-full max-w-md mx-auto relative z-50 flex-col space-y-4 overflow-y-auto h-[70vh]">
              <ApproveModal
                user={selecteduser}
                setShowmodal={setShowmodal}
                userdata={userdata}
                setUserdata={setUserdata}
                onClose={() => setShowmodal(false)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admincontrols;
