import Modal from "react-modal";

Modal.setAppElement("#root");

function CustomModal({ modalIsOpen, setModalIsOpen, modalData }) {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Modal"
        className="flex flex-col min-w-[30%] min-h-[30%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-slate-900 text-white p-6 rounded-md"
        overlayClassName="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-2xl mb-4">{modalData?.modalHeading}</h2>
        {modalData?.modalContent &&
          modalData.modalContent.map((content, index) => (
            <div key={index} className="text-lg mb-4">
              {content}
            </div>
          ))}
        <div className="flex-grow"></div>{" "}
        {/* Add this div to push buttons to the bottom */}
        <div className="flex justify-between gap-6">
          <button
            className="btn-primary"
            onClick={() => {
              modalData?.apiFunction(modalData?.toolid);
              setModalIsOpen(false);
            }}
          >
            Confirm
          </button>
          <button className="btn-primary" onClick={() => setModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default CustomModal;
