import { useEffect, useState } from "react";
import { IoIosPeople } from "react-icons/io";
import { MdOutlinePassword, MdVisibility, MdVisibilityOff, MdErrorOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import login_image from "../../../Assets/Images/login_image.jpg"


export default function RegistarPage() {
  const [useremail, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(0);
  const [teamname, setTeamname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [manageremail, setManageremail] = useState("");
  const [validPass, setValidpass] = useState(0);
  const [sending, setSending] = useState(false)

  const navigate = useNavigate();

  const registerHandler = async () => {
    setSending(true);
    try {
        const response = await fetch(`/api/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ useremail, password, firstname, lastname, teamname, manageremail })
        });

        const data = await response.json();

        if(response.status===200){
          toast.success("Account created, waiting for your manager's approval");
          navigate('/');
        }
        else{
          toast.error(data.message);
        }
        
    } catch (error) {
        toast.error("Internal server error!");
    }
    finally{
      setSending(false);
      setUseremail("");
      setPassword("");
      setFirstname("");
      setLastname("");
      setManageremail("");
      setTeamname("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      registerHandler();
    }
  };

  useEffect(() => {
    const upper = /[A-Z]/.test(password);
    const lower = /[a-z]/.test(password);
    const num = /[0-9]/.test(password);
    const symb = /[!@#$%^&*()-_+=<>?]/.test(password);
  
    if (upper && lower && num && symb && password.length>=8) {
      setValidpass(1); 
    } else {
      setValidpass(0);
    }
  }, [password]);

  return (
<div className="flex justify-center items-center h-[108vh] -mt-16 bg-gray-200">
    <div className="flex justify-center items-center h-[40vh] w-[100vw]">
      <div className="flex px-8 py-12 justify-center items-center rounded-3xl text-center w-[85vw] h-[80vh] border border-gray-100 shadow-md bg-white bg-opacity-50 backdrop-blur">
        <div className="w-1/2">
          <img src={login_image} alt="register_page_image" className="w-[35vw] h-[70vh] object-cover rounded-3xl"/>
        </div>
        <div className="w-1/2 ml-7">
          <div>
            <h2 className="flex justify-center text-3xl font-bold tracking-tight text-center mb-10">
              <IoIosPeople className="icon" /> Create a WinDash Account
            </h2>
          </div>

          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your email"
                name="useremail"
                value={useremail}
                onChange={(e) => setUseremail(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <div className="relative ml-5 flex items-center justify-between">
            <div>
              <MdOutlinePassword className="text-xl mr-4" />
            </div>
            <div className="flex items-center mr-auto relative">
              <input
                type={showPassword ? 'text' : 'password'}
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black" 
                placeholder="Enter Password"
                name="password"
                value={password}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="text-gray-600 px-1 py-4 mb-4 mr-3 absolute right-0" 
              >
                {showPassword ? <MdVisibilityOff className="text-xl" /> : <MdVisibility className="text-xl" />}
              </button>
              {validPass===0?
              <div className="group">
              <span className="absolute right-10 h-[4.5vh] transform -translate-y-1/2 text-red-600 cursor-pointer">
                <MdErrorOutline size={23}/>
              </span>
              <span className="absolute right-10 top-14 px-2 py-1 z-10 bg-red-600 text-white rounded-md hidden group-hover:block">
                Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one special symbol and one number.
              </span>
              </div>
              :<p></p>}
            </div>
          </div>
          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your first name"
                name="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your last name"
                name="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your team name"
                name="teamname"
                value={teamname}
                onChange={(e) => setTeamname(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <div>
            <label className="flex items-center w-full justify-between ml-5">
              <div>
                <FaUser className="icon text-xl mr-4"></FaUser>
              </div>
              <input
                type="text"
                className="placeholder:text-gray-500 px-5 py-4 mb-4 focus:outline-none w-[30vw] mr-auto bg-white bg-opacity-60 backdrop-blur rounded-xl border-2 focus:border-black"
                placeholder="Enter your manager's email ID"
                name="manageremail"
                value={manageremail}
                onChange={(e) => setManageremail(e.target.value)}
                onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
              />
            </label>
          </div>
          <button
            disabled={sending && !(useremail && validPass && firstname && lastname && teamname && manageremail)}
            onClick={registerHandler}
            className="btn-primary px-6 mt-8 mb-4 w-[30vw] h-[6vh] rounded-xl mr-5"
          >
            Submit request
          </button>
        </div>
      </div>
    </div>
    </div>
  );
}
