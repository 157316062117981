import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ toolname, toolid, tooldesc, toolicon }) => {
  return (
    <Link
      to={`/all/${toolname}/${toolid}`}
      className="max-w-md rounded-3xl overflow-hidden shadow-xl m-4 list-tools-card w-72 group" 
    >
      <img
        className="w-[9vw] h-[15vh] mx-auto"
        src={toolicon}
        alt={toolname}
      />
      <div className="px-6 py-4 text-center">
        <div className="font-bold text-xl mb-2 group-hover:text-orange-700">{toolname}</div> 
        <div className="text-sm group-hover:text-[#BD4220]">{tooldesc}</div>
      </div>
    </Link>
  );
};

export default CategoryCard;
